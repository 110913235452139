import { z } from 'zod';

import { Http } from '@/helpers/http';

import { TeamCreateSchema } from '@/schemas/team-create.schema';
import { TeamDeleteBulkSchema } from '@/schemas/team-delete-bulk.schema';
import { TeamInviteCreateSchema } from '@/schemas/team-invite-create.schema';
import { TeamUpdateSchema } from '@/schemas/team-update.schema';
import { TeamUserUpdateSchema } from '@/schemas/team-user-update.schema';

import { Domain } from '@/models/domain.model';
import { TeamUser } from '@/models/team-user.model';
import { Team } from '@/models/team.model';

const url = `${import.meta.env.VITE_API_URL}/teams`;

export const TeamService = Object.freeze({
    async index() {
        return await Http.get<Team[]>(url);
    },

    async show(id: string) {
        return await Http.get<Team>(`${url}/${id}`);
    },

    async store(data: z.infer<typeof TeamCreateSchema>) {
        TeamCreateSchema.parse(data);

        return await Http.post<Team>(url, data);
    },

    async update(id: string, data: z.infer<typeof TeamUpdateSchema>) {
        TeamUpdateSchema.parse(data);

        return await Http.patch<Team>(`${url}/${id}`, data);
    },

    async delete(id: string) {
        return await Http.delete<Team>(`${url}/${id}`);
    },

    async indexDomains(teamId: string) {
        return await Http.get<Domain[]>(`${url}/${teamId}/domains`);
    },

    async updateUser(teamId: string, userId: string, data: z.infer<typeof TeamUserUpdateSchema>) {
        TeamUserUpdateSchema.parse(data);

        return await Http.patch<TeamUser[]>(`${url}/${teamId}/users/${userId}`, data);
    },

    async deleteUser(teamId: string, userId: string) {
        return await Http.delete<TeamUser[]>(`${url}/${teamId}/users/${userId}`);
    },

    async bulkDeleteUsers(teamId: string, data: z.infer<typeof TeamDeleteBulkSchema>) {
        return await Http.patch(`${url}/${teamId}/remove-users`, data);
    },

    async storeInvites(teamId: string, data: z.infer<typeof TeamInviteCreateSchema>) {
        TeamInviteCreateSchema.parse(data);

        return await Http.post<TeamUser>(`${url}/${teamId}/invites`, data);
    },

    async acceptInvite(teamId: string, inviteId: string) {
        return await Http.post(`${url}/${teamId}/invites/${inviteId}/accept`);
    },

    async deleteInvite(teamId: string, inviteId: string) {
        return await Http.delete(`${url}/${teamId}/invites/${inviteId}`);
    },
});
