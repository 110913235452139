import { createApp } from 'vue';

import { createPinia } from 'pinia';

import App from '@/App.vue';
import '@/assets/style.css';
import { initAxios } from '@/init/axios';
import { registerGlobals } from '@/init/globals';
import router from '@/router';
import * as Sentry from '@sentry/vue';
import 'tippy.js/dist/tippy.css';
import { setupCalendar } from 'v-calendar';

import i18n from '@/i18n';

// Create App

const app = createApp(App);

// Pinia

const pinia = createPinia();
app.use(pinia);

// Globals

registerGlobals(app);

// Internationalization

const locale = localStorage.getItem('økisnavn_locale');

if (locale) {
    i18n.global.locale.value = locale as 'fo' | 'en';
}

app.use(i18n);

// Axios

initAxios();

// Calendar

app.use(setupCalendar, {});

// Router

app.use(router);

// Sentry

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    attachProps: true,
    environment: import.meta.env.VITE_ENVIRONMENT,
    logErrors: true,
    trackComponents: true,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', 'http://okisnavn-backend.Test/', 'https://skipan.xn--kisnavn-p1a.fo/api/'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// Mount app

app.mount('#app');
