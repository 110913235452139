import { Http } from '@/helpers/http';

import { Settings } from '@/models/settings.model';

const url = `${import.meta.env.VITE_API_URL}/settings`;

export const SettingsService = Object.freeze({
    async index() {
        return await Http.get<Settings>(url);
    },

    async video() {
        return await Http.get<string>(`${url}/video`);
    },
});
