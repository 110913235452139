import { z } from 'zod';

import i18n from '@/i18n';

export const TeamCreateSchema = z.object({
    name: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
    vat_number: z.string().optional().nullable(),
    street: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    zip: z.string().optional().nullable(),
    attention: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
    domain_id: z.string().optional().nullable(),
});
