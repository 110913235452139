<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { useFocusTrap } from '@/composables/focus-trap';

// Options

defineOptions({
    inheritAttrs: false,
});

// Props

const props = defineProps<{
    active: boolean;
    focus?: boolean;
    title: string;
    locked?: boolean;
    backArrow?: boolean;
}>();

// Emits

const emit = defineEmits<{
    close: [];
    'click:back': [];
}>();

// Data

const element = ref<HTMLDivElement>();
const container = ref<HTMLDivElement>();

const focusTrap = useFocusTrap(element);

// Lifecycle Hooks

onMounted(async () => {
    setTimeout(() => {
        if (!container.value) {
            return;
        }

        container.value.style.display = '';
    }, 500);

    if (!props.locked) {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                emit('close');
            }
        });
    }
});

// Watch

watch(
    () => props.active,
    (active) => {
        if (!props.focus) {
            return;
        }

        focusTrap.setActive(active);
    },
);

// Functions

function onClick(e: Event) {
    if (!props.active || props.locked) {
        return;
    }

    const target = e.target as HTMLElement;

    if (!target.classList.contains('tint')) {
        return;
    }

    emit('close');
}
</script>

<template>
    <div
        ref="container"
        v-hyper-cloak
        class="tint fixed left-0 top-0 z-50 grid h-full w-full place-items-center bg-black/40 p-4 transition duration-400"
        :class="{
            'pointer-events-none opacity-0': !active,
        }"
        @mousedown="onClick"
    >
        <div
            v-bind="$attrs"
            ref="element"
            class="relative flex max-h-[80%] w-full max-w-screen-lg flex-col overflow-hidden rounded-xl bg-background shadow-large transition duration-400 sm:rounded-3xl"
            :class="{
                'scale-90': !active,
            }"
        >
            <div class="flex items-center justify-between border-b border-black-100 p-4 py-6 lg:p-8">
                <h2 class="flex items-center gap-4 text-h5 lg:text-h2">
                    <span
                        v-if="backArrow"
                        class="-m-4 cursor-pointer p-4"
                        @click="$emit('click:back')"
                    >
                        <VIcon
                            class="w-4"
                            name="back-arrow"
                        />
                    </span>

                    <span v-html="title"></span>
                </h2>

                <VIcon
                    v-if="!locked"
                    name="close-circle"
                    class="w-8 flex-shrink-0 cursor-pointer text-black"
                    @click="$emit('close')"
                />
            </div>

            <div class="overflow-auto">
                <slot />
            </div>

            <div class="flex w-full items-center justify-end border-t border-black-100 p-4 py-6 lg:p-8">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>
