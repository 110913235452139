import { TeamCreateSchema } from '@/schemas/team-create.schema';

export const TeamUpdateSchema = TeamCreateSchema.pick({
    name: true,
    vat_number: true,
    street: true,
    zip: true,
    city: true,
    attention: true,
    country: true,
});
