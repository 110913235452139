<script setup lang="ts">
import { computed, onBeforeMount, watch } from 'vue';

import { useRoute } from 'vue-router';

import { useEventBus } from '@/composables/event-bus';

import { SettingsService } from './services/settings.service';

import TheModals from '@/components/TheModals.vue';
import TheToasts from '@/components/TheToasts.vue';
import TheLayout from '@/components/layout/TheLayout.vue';
import TheNotificatons from '@/components/layout/TheNotifications.vue';

Dialog.register();

// Composables

const eventBus = useEventBus();
const route = useRoute();

// Computed

const renderLayout = computed(
    () =>
        $auth.user &&
        route.name !== 'auth-login' &&
        route.name !== '404' &&
        route.name !== 'domain-invite-accept' &&
        route.name !== 'team-invite-accept' &&
        !route.name?.toString().includes('admin'),
);

// Lifecycle Hooks

onBeforeMount(async () => {
    await $auth.me();

    const { response, error } = await SettingsService.video();

    if (error) {
        return;
    }

    $store.videoUrl = response.data;
});

// Watchers

watch(
    () => $auth.user,
    async (user) => {
        if (user) {
            await $store.getSettings();
            await $store.getTeams(true);
            await $store.getPaymentCards();
        }
    },
);

watch(
    () => route,
    () => {
        $store.conceal = false;
    },
    { deep: true },
);

// Events

eventBus.listen('teams:refresh', getTeams);

// Functions

async function getTeams() {
    await $store.getTeams(true);
}
</script>

<template>
    <div
        class="transition"
        :class="{ 'opacity-0': $store.conceal }"
    >
        <TheModals />

        <TheToasts />

        <RouterView v-if="!renderLayout" />

        <template v-if="renderLayout">
            <TheNotificatons />

            <TheLayout>
                <RouterView v-slot="{ Component }">
                    <transition name="fade-in">
                        <component :is="Component" />
                    </transition>
                </RouterView>
            </TheLayout>
        </template>
    </div>
</template>

<style scoped>
.fade-in-enter-from,
.fade-in-leave-to {
    opacity: 0;
}

.fade-in-enter-active {
    transition: opacity 0.4s ease;
}

.fade-in-leave-active {
    transition: opacity 0s;
}
</style>
