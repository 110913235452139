import { App } from 'vue';

import { hyperCloakDirective } from '@/directives/hyper-cloak';
import { tippyDirective } from '@/directives/tippy';

import i18n from '@/i18n';

import { useStore } from '@/store';
import { useAuthStore } from '@/store/auth.store';

import { useValidation } from '@/composables/validation';

import { Dialog } from '@/helpers/dialog';
import { Format } from '@/helpers/format';
import { Modal } from '@/helpers/modal';
import { Routing } from '@/helpers/routing';
import { Session } from '@/helpers/session';
import { Toast } from '@/helpers/toast';

import VIcon from '@/components/VIcon.vue';

export function registerGlobalHelpers() {
    window.Dialog = Dialog;
    window.Format = Format;
    window.Modal = Modal;
    window.Toast = Toast;
    window.Routing = Routing;
    window.Session = Session;
    window.$v = useValidation;
    window.$t = i18n.global.t;

    // Stores
    window.$store = useStore();
    window.$auth = useAuthStore();
}

export function registerGlobalProperties(app: App) {
    app.config.globalProperties.$dialog = Dialog;
    app.config.globalProperties.$format = Format;
    app.config.globalProperties.$modal = Modal;
    app.config.globalProperties.$toast = Toast;
    app.config.globalProperties.$routing = Routing;
    app.config.globalProperties.$session = Session;

    // Stores
    app.config.globalProperties.$store = useStore();
    app.config.globalProperties.$auth = useAuthStore();
}

export function registerGlobalComponents(app: App) {
    app.component('VIcon', VIcon);
}

/**
 * Register globals.
 * @param app - Vue App
 */
export function registerGlobals(app: App) {
    registerGlobalHelpers();

    registerGlobalProperties(app);

    registerGlobalComponents(app);

    app.directive('tippy', tippyDirective);
    app.directive('hyper-cloak', hyperCloakDirective);
}
