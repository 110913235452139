<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import { useEventBus } from '@/composables/event-bus';

// Composables

const route = useRoute();
const router = useRouter();
const eventBus = useEventBus();

// Data

const title = ref(route.meta.title);

// Computed

const numberOfNotifications = computed(() => {
    if (!$auth.isUserVerified) {
        return '';
    }

    const number = $auth.user?.unread_notifications_count || 0;

    if (number === 0) {
        return '';
    }

    return number > 9 ? `9+` : `${number}`;
});

// Events

/**
 * Update the title of the page when the "header:title" event is emitted.
 */
eventBus.listen('header:title', ($title: string) => (title.value = $title));

// Watchers

watch(
    () => route.name,
    () => {
        if (route.meta.title) {
            title.value = $t(route.meta.title);
            return;
        }

        title.value = '';
    },
);

// Functions

function openNotifications() {
    if (!$auth.isUserVerified) {
        return;
    }

    $store.notificationsIsOpen = true;
}

function goBack() {
    if (typeof route.meta.backOption === 'string') {
        Routing.go(router, route.meta.backOption);
        return;
    }

    router.go(-1);
}
</script>

<template>
    <header class="flex w-screen items-center justify-between bg-background px-4 py-1 lg:w-full lg:bg-white lg:p-8 lg:pb-10">
        <h1 class="hidden items-center justify-start gap-5 lg:flex">
            <div
                v-if="$route.meta.backOption && title"
                class="flex h-full cursor-pointer items-center"
                @click="goBack"
            >
                <VIcon
                    class="w-5"
                    name="back-arrow"
                />
            </div>

            <span>{{ title }}&nbsp;</span>
        </h1>

        <div class="flex gap-2.5 lg:hidden">
            <VIcon
                class="w-9"
                name="menu"
                @click="$store.isSidebarOpen = true"
            />

            <VIcon
                class="w-[6.25rem]"
                name="logo"
            />
        </div>

        <div
            v-if="$auth.user"
            class="relative flex cursor-pointer items-center rounded-full border border-black-50 lg:p-1"
            @click="openNotifications"
        >
            <div class="flex h-8 w-8 items-center justify-center rounded-full lg:mr-5 lg:h-[3.75rem] lg:w-[3.75rem] lg:bg-black-50">
                <VIcon
                    class="w-4 lg:w-5"
                    name="bell"
                />
            </div>

            <p class="mr-4 hidden text-black-100 lg:flex">{{ $t('Fráboðanir') }}</p>

            <div
                v-if="numberOfNotifications.length > 0"
                class="absolute right-0 top-0 flex h-3 w-3 items-center justify-center rounded-full bg-danger text-[0.375rem] text-white lg:static lg:mr-2 lg:h-7 lg:w-7 lg:text-xs"
            >
                {{ numberOfNotifications }}
            </div>
        </div>
    </header>

    <div class="mb-4 flex border-b border-background px-5 py-3 lg:hidden">
        <div class="flex items-center gap-3">
            <div
                v-if="$route.meta.backOption"
                class="flex h-full cursor-pointer items-center"
                @click="goBack"
            >
                <VIcon
                    class="w-3"
                    name="back-arrow"
                />
            </div>

            <p class="text-xs">{{ title }} &nbsp;</p>
        </div>
    </div>
</template>
