import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export async function teamGuard(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    await $auth.waitUntilFetched();

    if (to.name?.toString().includes('team') && !to.params.teamId) {
        return next({ name: 'domains' });
    }

    return next();
}
