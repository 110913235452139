<!-- This component exists, because the VIcon component uses import() and this can take a few ms. This caused the sidebar logo to jump. -->

<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 160 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1777_4937)">
            <path
                d="M22.5908 16.6513V20.7544H19.4111V0.332031H22.5908V12.66L29.0923 6.11216H32.7438L27.2947 11.7805L33.1596 20.7544H29.3957L25.0813 14.0389L22.5908 16.6513Z"
                fill="black"
            />
            <path
                d="M36.3616 6.1118H39.515V20.754H36.3616V6.1118ZM39.7922 1.92671C39.7922 3.02609 38.9907 3.82733 37.9383 3.82733C36.8859 3.82733 36.0845 3.02981 36.0845 1.92671C36.0845 0.823602 36.8859 0 37.9383 0C38.9907 0 39.7922 0.797516 39.7922 1.92671Z"
                fill="black"
            />
            <path
                d="M54.443 7.46157L53.282 9.80194C51.9824 9.00442 50.3495 8.50877 49.05 8.50877C47.7504 8.50877 46.8928 8.92244 46.8928 9.96964C46.8928 12.6678 54.7201 11.2069 54.6939 16.5212C54.6939 19.5212 52.0124 20.8703 49.05 20.8703C46.8928 20.8703 44.597 20.1547 43.1289 18.8355L44.2637 16.5771C45.537 17.7063 47.5819 18.392 49.1885 18.392C50.5443 18.392 51.5967 17.9224 51.5967 16.8231C51.5967 13.8231 43.8517 15.5038 43.8779 10.2156C43.8779 7.24169 46.4771 5.97461 49.3009 5.97461C51.1547 5.97461 53.1172 6.52616 54.4467 7.46157"
                fill="black"
            />
            <path
                d="M72.7681 11.6422V20.754H69.5885V12.5255C69.5885 10.323 68.2589 8.97391 66.0493 8.97391C63.615 9 62.0944 10.6807 61.8735 12.9652V20.754H58.6938V6.1118H61.8735V8.91801C62.9259 6.88323 64.8059 6 67.2964 6C70.727 6 72.7718 8.14658 72.7718 11.6422"
                fill="black"
            />
            <path
                d="M86.5355 15.6073V14.2582H82.5507C80.4759 14.2582 79.5096 14.8098 79.5096 16.211C79.5096 17.5303 80.5882 18.3837 82.3597 18.3837C84.6292 18.3837 86.3707 17.1986 86.5355 15.6035M86.5617 18.9613C85.5093 20.2545 83.824 20.888 81.693 20.888C78.5134 20.888 76.521 18.9352 76.521 16.3452C76.521 13.7551 78.5396 11.97 82.0825 11.9402H86.5355V11.5004C86.5355 9.65572 85.3445 8.55634 83.0226 8.55634C81.6106 8.55634 80.1463 9.05199 78.6782 10.0433L77.3786 7.84081C79.4272 6.62963 80.9178 5.99609 83.7117 5.99609C87.5018 5.99609 89.6327 7.9228 89.659 11.1427L89.6852 20.7501H86.558V18.9613H86.5617Z"
                fill="black"
            />
            <path
                d="M97.6806 20.7536L91.9805 6.11133H95.3024L99.3958 17.6418L103.433 6.11133H106.613L100.973 20.7536H97.6806Z"
                fill="black"
            />
            <path
                d="M123.717 11.6422V20.754H120.537V12.5255C120.537 10.323 119.211 8.97391 116.998 8.97391C114.564 9 113.043 10.6807 112.822 12.9652V20.754H109.643V6.1118H112.822V8.91801C113.875 6.88323 115.755 6 118.245 6C121.676 6 123.721 8.14658 123.721 11.6422"
                fill="black"
            />
            <path
                d="M131.421 19.1599C131.421 20.2332 130.676 21.0046 129.679 21.0046C128.683 21.0046 127.938 20.2332 127.938 19.1599C127.938 18.0866 128.683 17.3711 129.679 17.3711C130.676 17.3711 131.421 18.1127 131.421 19.1599Z"
                fill="black"
            />
            <path
                d="M139.106 4.9261V6.65902H142.952V9.02548H139.106V20.7497H135.952V9.02548H134.016V6.65902H135.952V5.22796C135.952 1.8143 138.469 0.189453 141.016 0.189453C142.207 0.189453 143.394 0.52113 144.334 1.20684L143.117 3.6292C142.51 3.18945 141.761 2.93976 141.098 2.93976C140.02 2.93976 139.106 3.54722 139.106 4.92237"
                fill="black"
            />
            <path
                d="M147.828 13.461C147.828 16.2971 149.682 18.2238 152.337 18.2238C154.993 18.2238 156.816 16.2971 156.816 13.461C156.816 10.625 154.963 8.72813 152.337 8.72813C149.712 8.72813 147.828 10.6548 147.828 13.461ZM160 13.435C160 17.8399 156.873 20.866 152.337 20.866C147.802 20.866 144.648 17.8399 144.648 13.435C144.648 9.02999 147.776 6.00391 152.337 6.00391C156.899 6.00391 160 9.00391 160 13.435Z"
                fill="black"
            />
            <path
                d="M3.17963 13.4611C3.17963 10.6548 5.03347 8.72813 7.68878 8.72813C8.44155 8.72813 9.13066 8.89211 9.72988 9.18279L11.8684 7.05484C10.6961 6.38031 9.28047 6.00391 7.68503 6.00391C3.12719 6.00018 0 9.00018 0 13.4312C0 15.0598 0.430691 16.502 1.20219 17.6685L3.50545 15.3766C3.29573 14.8027 3.17963 14.1579 3.17963 13.4573"
                fill="black"
            />
            <path
                d="M11.8344 11.527C12.0516 12.1046 12.1715 12.7568 12.1715 13.4612C12.1715 16.2972 10.3176 18.2239 7.68853 18.2239C6.90955 18.2239 6.20171 18.0525 5.58376 17.7469L3.50146 19.8189C4.67369 20.4897 6.08936 20.8661 7.68479 20.8661C12.2202 20.8661 15.3474 17.84 15.3474 13.4351C15.3474 11.8065 14.9204 10.3717 14.1639 9.20898L11.8344 11.527Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_1777_4937">
                <rect
                    width="160"
                    height="21"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>
