import { ref } from 'vue';

type Toast = {
    id: number;
    message: string;
    type: ToastType;
};

type ToastType = 'info' | 'error';

type ToastOptions = { message: string; type?: ToastType };

const registeredToasts = ref([] as Toast[]);

export const Toast = {
    registeredToasts,

    add(options: ToastOptions) {
        const id = Date.now();

        registeredToasts.value.push({
            id,
            message: options.message,
            type: options.type || 'info',
        });

        setTimeout(() => {
            this.remove(id);
        }, 5000);

        return id;
    },

    remove(id: number) {
        registeredToasts.value = registeredToasts.value.filter((toast) => toast.id != id);
    },

    clear() {
        registeredToasts.value = [];
    },
};
