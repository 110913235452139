import { z } from 'zod';

import { Http } from '@/helpers/http';

import { UserCreateSchema } from '@/schemas/user-create.schema';
import { UserUpdatePasswordSchema } from '@/schemas/user-update-password.schema';
import { UserSamleikinUpdateSchema, UserUpdateSchema } from '@/schemas/user-update.schema';

import { Notification } from '@/models/notification.model';
import { Pagination } from '@/models/pagination.model';
import { User } from '@/models/user.model';

const url = `${import.meta.env.VITE_API_URL}/users`;

export const UserService = Object.freeze({
    async me() {
        return await Http.get<User>(`${url}/me`);
    },

    async index() {
        return await Http.get<User[]>(`${url}`);
    },

    async show(id: string) {
        return await Http.get<User>(`${url}/${id}`);
    },

    async store(data: z.infer<typeof UserCreateSchema>) {
        UserCreateSchema.parse(data);

        return await Http.post<User>(`${url}`, data);
    },

    async update(data: z.infer<typeof UserUpdateSchema>) {
        UserUpdateSchema.parse(data);

        return await Http.patch<User>(`${url}/me`, data);
    },

    async updateSamleikinUser(data: z.infer<typeof UserSamleikinUpdateSchema>) {
        UserSamleikinUpdateSchema.parse(data);

        return await Http.patch<User>(`${url}/me`, data);
    },

    async updateLanguage(language: 'en' | 'fo') {
        return await Http.patch<{ status: string }>(`${url}/me/language`, { language });
    },

    async updatePassword(data: z.infer<typeof UserUpdatePasswordSchema>) {
        UserUpdatePasswordSchema.parse(data);

        return await Http.patch<{ status: string; access_token: string }>(`${url}/me/password`, data);
    },

    async notifications(page: number, perPage: number) {
        return await Http.get<Pagination<Notification>>(`${url}/me/notifications?page=${page}&per_page=${perPage}`);
    },

    async markAsRead(notificationId: string) {
        return await Http.patch(`${url}/me/notifications/${notificationId}/mark-as-read`);
    },

    async markAllRead() {
        return await Http.patch(`${url}/me/notifications/mark-as-read`);
    },

    async delete() {
        return await Http.delete(`${url}/me`);
    },
});
