import { computed, type ComputedRef } from 'vue';

import type { ZodEffects, ZodIssue, ZodObject, ZodUnion } from 'zod';

// Validate a form, given a Zod schema.
export function useValidation<T, U extends (form: T) => any>(form: T, schema: ZodObject<any> | ZodEffects<any> | ZodUnion<any>, transform: U) {
    return {
        errorMessages: computed(() => {
            const parsed = schema.safeParse(transform(form));

            if (parsed.success) {
                return {};
            }

            return parsed.error.errors.reduce((previous: any, current: ZodIssue) => {
                for (let i = 0; i < current.path.length; i++) {
                    const field = current.path.slice(0, i + 1).join('.');

                    if (i == current.path.length - 1) {
                        previous[field] = current.message;
                    }
                }

                return previous;
            }, {});
        }) as ComputedRef<any>,

        // Indicates whether the form data is valid.
        valid: computed(() => {
            const { success } = schema.safeParse(transform(form));

            return success;
        }),

        // The transformed form.
        data: computed(() => transform(form)) as ComputedRef<ReturnType<U>>,
    };
}
