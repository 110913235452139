<script setup lang="ts">
import { Ref, computed, onMounted, ref, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';

import { useEventBus } from '@/composables/event-bus';

import { Option } from '@/models/option.model';

// Composables

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

// Data

const options: Ref<Option[]> = ref([{ label: $t('Míni økisnøvn'), value: 'my-domains' }]);

const selected: Ref<Option> = ref(options.value[0]);

const redirect = ref(true);

// Computed

const teamOptions = computed(() => {
    if (!$store.teams) {
        return [];
    }

    return $store.teams.map((team) => ({ label: team.name, value: team.id }));
});

// Lifecycle Hooks

onMounted(() => {
    const interval = setInterval(async () => {
        if ($auth.user) {
            clearInterval(interval);

            if ($auth.user?.has_shared_domains) {
                options.value = [...options.value, { label: $t('Deild økisnøvn'), value: 'domains-shared' }];
            }

            const data = await $store.getData();

            if (data.teamId) {
                const foundTeam = teamOptions.value.find((team) => team.value === data.teamId);

                if (foundTeam) {
                    redirect.value = false;
                    selected.value = foundTeam;

                    return;
                }
            }

            if (data.shared) {
                redirect.value = false;

                if (!options.value[1]) {
                    options.value = [...options.value, { label: $t('Deild økisnøvn'), value: 'domains-shared' }];
                }

                selected.value = options.value[1] || { label: $t('Deild økisnøvn'), value: 'domains-shared' };
            }
        }
    }, 20);
});

// Watchers

watch(
    () => route,
    async (route) => {
        if (route.name === 'domains') {
            if (selected.value.value === 'my-domains') {
                return;
            }

            selected.value = options.value[0];
            return;
        }

        if (route.name === 'domains-shared' && options.value[1].value === 'domains-shared') {
            if (selected.value.value === 'domains-shared') {
                return;
            }

            selected.value = options.value[1];
            return;
        }

        const foundTeam = teamOptions.value.find((team) => team.value === route.params?.teamId);

        if (foundTeam) {
            if (selected.value.value === foundTeam.value) {
                return;
            }

            selected.value = foundTeam;
        }
    },
    { deep: true },
);

watch(selected, async (newSelected, oldSelected) => {
    if (!newSelected || newSelected.value === oldSelected.value) {
        return;
    }

    $store.isSidebarOpen = false;

    if (newSelected.value === 'my-domains' || newSelected.value === 'domains-shared') {
        if (redirect.value) {
            if (newSelected.value === 'domains-shared') {
                await $store.setData({ shared: true });
                router.push({ name: 'domains-shared' });
            } else {
                await $store.setData({});
                router.push({ name: 'domains' });
            }
        }

        redirect.value = true;

        await $store.getPaymentCards();

        return;
    }

    if (redirect.value) {
        await $store.setData({ teamId: newSelected.value.toString() });

        router.push({ name: 'teams-domains', params: { teamId: newSelected.value } });

        await $store.getPaymentCards();

        eventBus.emit('domains:refresh');
    }

    redirect.value = true;
});

watch(teamOptions, (teamOptions) => {
    if (teamOptions.length === 0 && selected.value.value !== 'my-domains' && selected.value.value !== 'domains-shared') {
        selected.value = options.value[0];
        return;
    }

    const isTeam = teamOptions.find((team) => team.value === selected.value.value);

    if (!isTeam) {
        return;
    }

    selected.value = isTeam;

    $store.getPaymentCards();
});

// Events

eventBus.listen('sidebar:refresh', refreshTeams);

// Functions

async function refreshTeams() {
    const storage = await $store.getData();

    const foundTeam = teamOptions.value.find((team) => team.value === storage.teamId);

    redirect.value = false;

    if (foundTeam) {
        selected.value = foundTeam;

        return;
    }

    selected.value = options.value[0];
}
</script>

<template>
    <Listbox
        v-slot="{ open }"
        v-model="selected"
        as="div"
    >
        <div class="relative">
            <ListboxButton
                class="relative w-full cursor-pointer rounded-lg bg-white py-2.5 pl-3 pr-10 text-left text-base focus:shadow-small focus:outline-none"
                :class="{ 'shadow-small': open }"
            >
                <span class="block truncate text-sm">
                    {{ selected.label }}
                </span>

                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <VIcon
                        name="chevron"
                        class="h-1.5 w-3.5 transition duration-400"
                        :class="{ 'rotate-180': open }"
                    />
                </span>
            </ListboxButton>

            <transition
                enter-active-class="transition ease-in duration-200"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions class="mt-1 max-h-80 w-full overflow-auto rounded-lg bg-white py-2 focus:outline-none">
                    <ListboxOption
                        v-for="option in options"
                        :key="option.value"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="option"
                    >
                        <li :class="[(active || selected) && '!bg-black-50', 'relative flex h-11 w-full cursor-pointer items-center pl-3 pr-9']">
                            <span class="block truncate text-sm font-normal">{{ option.label }}</span>

                            <div
                                v-if="selected"
                                class="absolute right-3 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-black bg-black transition duration-400"
                            >
                                <VIcon
                                    name="checkmark"
                                    class="-mb-[1px] -ml-0.5 h-3 w-3.5 text-black-50 transition duration-400"
                                />
                            </div>
                        </li>
                    </ListboxOption>

                    <template v-if="teamOptions.length > 0">
                        <div class="my-2 h-[1px] w-full bg-black-50"></div>

                        <ListboxOption
                            v-for="option in teamOptions"
                            :key="option.value"
                            v-slot="{ active, selected }"
                            as="template"
                            :value="option"
                        >
                            <li :class="[active && '!bg-black-50', 'relative flex h-11 w-full cursor-pointer items-center pl-3 pr-9']">
                                <span class="block truncate text-sm font-normal">{{ option.label }}</span>

                                <div
                                    v-if="selected"
                                    class="absolute right-3 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-black bg-black transition duration-400"
                                >
                                    <VIcon
                                        name="checkmark"
                                        class="-mb-[1px] -ml-0.5 h-3 w-3.5 text-black-50 transition duration-400"
                                    />
                                </div>
                            </li>
                        </ListboxOption>
                    </template>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>
