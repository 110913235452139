import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export async function authGuard(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    await $auth.waitUntilFetched();

    if (!to.meta.auth) {
        return next();
    }

    if (!$auth.user) {
        return next({ name: 'auth-login' });
    }

    return next();
}
