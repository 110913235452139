export const Session = {
    async set(key: string, value: any, expires = false): Promise<void> {
        let storageValue = JSON.stringify(value);

        if (expires) {
            const now = new Date();
            const tenMinutesLater = new Date(now.getTime() + 10 * 60000);

            storageValue = JSON.stringify({
                ...value,
                expires: tenMinutesLater,
            });
        }

        localStorage.setItem(`økisnavn_session_${key}`, storageValue);
    },

    async get(key: string): Promise<any> {
        const value = localStorage.getItem(`økisnavn_session_${key}`);

        if (!value) {
            return null;
        }

        const storageValue = JSON.parse(value);

        if (storageValue.expires) {
            const now = new Date();

            if (now > new Date(storageValue.expires)) {
                localStorage.removeItem(`økisnavn_session_${key}`);
                return null;
            }
        }

        return storageValue;
    },

    async remove(key: string): Promise<void> {
        localStorage.removeItem(`økisnavn_session_${key}`);
    },

    async removeAll(): Promise<void> {
        for (const key in localStorage) {
            if (key.indexOf('økisnavn_session') === 0) {
                localStorage.removeItem(`${key}`);
            }
        }
    },
};
