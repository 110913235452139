import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import i18n from '@/i18n';

import { latinizeCharacterMap } from './latinize';

dayjs.extend(updateLocale);

const StringFormatter = {
    latinize(string: string) {
        return string
            .split('')
            .map((char: keyof typeof latinizeCharacterMap | string) => latinizeCharacterMap[char] || char)
            .join('');
    },

    camelCase(string: string) {
        return this.latinize(string)
            .replace(/[\s\W]+./g, (match) => match.slice(-1).toUpperCase()) // Convert first letter of each word to uppercase
            .replace(/[^\w]+/g, '') // Remove all non-word characters
            .replace(/^(.)/, (match) => match.toLowerCase()); // Lowercase the first letter of the string
    },

    snakeCase(string: string) {
        return this.latinize(string)
            .replace(/[\s\W]+/g, '_') // Replace spaces and non-word characters with underscore
            .replace(/[^\w_]+/g, '') // Remove all non-word characters except underscore
            .replace(/^_+|_+$/g, '') // Trim leading and trailing underscores
            .toLowerCase();
    },

    pascalCase(string: string) {
        return this.latinize(string)
            .replace(/[\s\W]+./g, (match) => match.slice(-1).toUpperCase()) // Convert first letter of each word to uppercase
            .replace(/[^\w]+/g, '') // Remove all non-word characters
            .replace(/^(.)/, (match) => match.toUpperCase()); // Uppercase the first letter of the string
    },
};

export const Format = {
    date(date: string | number | Date = new Date(), format = 'DD.MM.YYYY') {
        if (i18n.global.locale.value === 'fo') {
            dayjs.updateLocale('en', {
                months: ['januar', 'februar', 'mars', 'apríl', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
            });
        } else {
            dayjs.updateLocale('en', {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            });
        }

        return dayjs(date).format(format);
    },

    dateTime(date: string | number | Date = new Date(), format = 'DD.MM.YYYY kl. HH:mm') {
        if (i18n.global.locale.value === 'fo') {
            dayjs.updateLocale('en', {
                months: ['januar', 'februar', 'mars', 'apríl', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
            });
        } else {
            dayjs.updateLocale('en', {
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            });
        }

        return dayjs(date).format(format);
    },

    number(number: number, options: Intl.NumberFormatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }) {
        if (isNaN(number)) {
            return null;
        }

        return Intl.NumberFormat('fo', options).format(number);
    },

    currency(number: number) {
        if (isNaN(number)) {
            return null;
        }

        const total = number / 100;

        return total.toLocaleString('de-DE');
    },

    message(message: string, params: Record<string, Date> | undefined) {
        if (!params) {
            return message;
        }
        return Object.entries(params).reduce((previous, [key, value]) => {
            return previous.replace(`:${key}`, this.date(value));
        }, message);
    },

    string: StringFormatter,
};
