import { Http } from '@/helpers/http';

import { Country } from '@/models/country.model';

const url = `${import.meta.env.VITE_API_URL}/countries`;

export const CountryService = Object.freeze({
    async index() {
        return await Http.get<Country[]>(url);
    },
});
