import AlertModal from '@/components/modals/dialog/AlertModal.vue';
import ConfirmModal from '@/components/modals/dialog/ConfirmModal.vue';
import PromptModal from '@/components/modals/dialog/PromptModal.vue';

type DialogOptions = {
    title: string;
    text: string;
    callback: (value?: string) => void;
    buttonText?: string;
    buttonType?: 'default' | 'secondary' | 'danger';
};

type CancelButton = {
    cancelButtonText?: string;
    cancelCallback?: () => void;
};

export const Dialog = {
    // Important: The modals need to be registered in App.vue.
    register(): void {
        Modal.register([AlertModal, ConfirmModal, PromptModal], true);
    },

    alert(options: DialogOptions) {
        Modal.open('AlertModal', options);
    },

    confirm(options: DialogOptions & CancelButton) {
        Modal.open('ConfirmModal', options);
    },

    prompt(options: DialogOptions & CancelButton & { inputType?: string }) {
        Modal.open('PromptModal', options);
    },

    close() {
        Modal.close('AlertModal');
        Modal.close('ConfirmModal');
        Modal.close('PromptModal');
    },
};
