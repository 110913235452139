import { z } from 'zod';

import i18n from '@/i18n';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export const ChangePhoneSchema = z.object({
    phone: z.string().regex(phoneRegex, i18n.global.t('Vinarliga skriva eitt rætt telefonnummar.')),
    phone_code: z.string({ message: i18n.global.t('Vinarliga áset telefonkotu.') }),
});
