/**
 * Cloak an element for 1 second to prevent FOUC.
 */
export const hyperCloakDirective = {
    mounted(el: any) {
        el.style.visibility = 'hidden';

        setTimeout(() => {
            el.style.visibility = '';
        }, 1000);
    },
};
