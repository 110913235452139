<script setup lang="ts">
import { Ref, onMounted, ref, watch } from 'vue';

import { useInfiniteScroll } from '@vueuse/core';

import { UserService } from '@/services/user.service';

import { Notification } from '@/models/notification.model';

import VButton from '@/components/VButton.vue';

// Data

const loading = ref(false);
const contentLoading = ref(false);

const element = ref<HTMLElement>();

const page = ref(1);
const perPage = ref(10);
const nextPage = ref(true);

const notifications: Ref<Notification[]> = ref([]);

// Lifecycle Hooks

onMounted(() => {
    if (!$auth.isUserVerified) {
        return;
    }

    init();
});

// Watchers

watch(
    () => $auth.isUserVerified,
    (isUserVerified) => {
        if (isUserVerified) {
            init();
        }
    },
);

watch(
    () => $store.notificationsIsOpen,
    (notificationsIsOpen) => {
        const htmlElement = document.querySelector('html');

        if (htmlElement) {
            if (notificationsIsOpen) {
                htmlElement.style.overflowY = 'hidden';

                return;
            }

            htmlElement.style.overflowY = 'auto';
        }

        if (!notificationsIsOpen) {
            setTimeout(() => {
                scrollToTop();
            }, 400);
        }
    },
);

// Functions

function init() {
    getNotifications();

    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            closeNotifications();
        }
    });
}

useInfiniteScroll(
    element,
    () => {
        if (!$auth.isUserVerified || !$store.notificationsIsOpen) {
            return;
        }

        page.value = page.value + 1;

        getNotifications();
    },
    { distance: perPage.value },
);

function scrollToTop() {
    element.value?.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

async function getNotifications(reset?: boolean) {
    if (!nextPage.value || contentLoading.value) {
        return;
    }

    contentLoading.value = true;

    const { response } = await UserService.notifications(page.value, perPage.value);

    contentLoading.value = false;

    if (response) {
        if (reset) {
            scrollToTop();

            notifications.value = response.data.data;
        } else {
            notifications.value = [...notifications.value, ...response.data.data];
        }

        nextPage.value = !!response.data.links.next;
    }
}

async function markAllAsRead() {
    if (loading.value) {
        return;
    }

    loading.value = true;

    const { error } = await UserService.markAllRead();

    loading.value = false;

    if (error) {
        Toast.add({ message: error.response?.data.message as string, type: 'error' });
        return;
    }

    page.value = 1;
    nextPage.value = true;

    $auth.me();

    getNotifications(true);
}

async function markAsRead(notificationId: string) {
    const { error } = await UserService.markAsRead(notificationId);

    if (error) {
        Toast.add({ message: error.response?.data.message as string, type: 'error' });
        return;
    }

    let foundNotificationIndex = -1;

    const foundNotification = notifications.value.find((notification, index) => {
        if (notification.id === notificationId) {
            foundNotificationIndex = index;
            return true;
        }
    });

    if (foundNotification) {
        notifications.value[foundNotificationIndex] = {
            ...foundNotification,
            read_at: new Date(),
        };
    }
}

function closeNotifications() {
    $store.notificationsIsOpen = false;
}
</script>

<template>
    <div
        v-hyper-cloak
        class="tint fixed left-0 top-0 z-50 grid h-full w-full place-items-center bg-black/30 p-4 transition"
        :class="{
            'pointer-events-none opacity-0': !$store.notificationsIsOpen,
        }"
        @click="closeNotifications"
    />

    <div
        v-hyper-cloak
        class="fixed right-0 z-50 h-full w-[30rem] max-w-[100vw] translate-x-full rounded-l-[1.25rem] bg-white shadow-none transition duration-400"
        :class="{ '!translate-x-0 !shadow-medium': $store.notificationsIsOpen }"
    >
        <div class="flex h-28 items-center justify-between px-8">
            <h5>{{ $t('Fráboðanir') }}</h5>

            <div class="flex items-center gap-3">
                <VButton
                    :text="$t('Markera alt sum lisið')"
                    type="secondary"
                    small
                    :loading="loading"
                    @click="markAllAsRead"
                />
                <VIcon
                    name="close"
                    class="h-11 w-11 cursor-pointer"
                    @click="closeNotifications"
                />
            </div>
        </div>

        <div
            v-if="!contentLoading && !notifications.length"
            class="flex h-full items-center justify-center"
        >
            <div class="flex flex-col items-center justify-center gap-10">
                <VIcon
                    name="bell"
                    class="h-9 w-9"
                />
                <h5>{{ $t('Tú hevur ongar fráboðanir í løtuni') }}</h5>
            </div>
        </div>

        <div
            v-if="notifications.length"
            ref="element"
            class="flex h-[calc(100%-7rem)] flex-col overflow-y-auto px-5"
        >
            <div
                v-for="notification in notifications"
                :key="notification.id"
                class="group relative flex flex-col gap-2 border-b border-black-50 py-5 pl-7 pr-3 transition duration-400"
                :class="{
                    'bg-danger/10': notification.data.type === 'danger',
                    'opacity-60': notification.read_at,
                }"
            >
                <div
                    class="pointer-events-none absolute right-3 top-3 z-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-black opacity-0 transition duration-400 group-hover:pointer-events-auto group-hover:opacity-100"
                    :class="{
                        'bg-black': notification.data.type === 'danger',
                        '!pointer-events-none !z-0 !opacity-0': notification.read_at,
                    }"
                    @click="markAsRead(notification.id)"
                >
                    <VIcon
                        class="w-3"
                        :class="{ 'text-white': notification.data.type === 'danger' }"
                        name="checkmark"
                    />
                </div>
                <div class="relative">
                    <div
                        v-if="!notification.read_at"
                        class="absolute -left-4 bottom-[0.3125rem] h-2 w-2 rounded-full bg-black"
                    />
                    <p class="text-xs text-black-100">
                        {{ notification.data.target }}&nbsp;•&nbsp;{{ $format.dateTime(notification.created_at, `DD. MMMM YYYY ${$t('kl.')} HH:mm`) }}
                    </p>
                </div>

                <p class="text-sm">{{ notification.data.message }}</p>
            </div>
            <div
                v-if="!nextPage"
                class="min-h-[1.25rem]"
            />
        </div>
    </div>
</template>
