import en from '@/lang/en.json';
import fo from '@/lang/fo.json';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'fo',
    messages: {
        en,
        fo,
    },
    legacy: false,
    warnHtmlMessage: false,
});

window.$t = i18n.global.t;

export default i18n;
