import { z } from 'zod';

import i18n from '@/i18n';

export const UserCreateSchema = z.object({
    name: z.string(),
    street: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
    zip: z
        .string()
        .regex(/^[0-9]+$/, { message: i18n.global.t('Hetta skal vera eitt tal.') })
        .min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
    city: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
    country_code: z.string(),
    language: z.string(),
    email: z.string().email(i18n.global.t('Vinarliga skriva ein rættan teldubústað.')),
});
