import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export async function middleware(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    await $auth.waitUntilFetched();

    if (to.name === 'search-domain' && to.query.domains) {
        const domains = to.query.domains
            .toString()
            .split(',')
            .map((domain) => {
                const searchString = domain.endsWith('.fo') ? domain : `${domain}.fo`;

                return searchString.toLocaleLowerCase();
            })
            .join(',');

        await Session.set('search_domains', { domains }, true);
    }

    if (to.name === 'team-invite-accept' && to.params.teamId && to.params.inviteId) {
        await Session.set('invitation', { type: 'team', id: to.params.teamId as string, inviteId: to.params.inviteId as string }, true);
    }

    if (to.name === 'domain-invite-accept' && to.params.domainId && to.params.inviteId) {
        await Session.set('invitation', { type: 'domain', id: to.params.domainId as string, inviteId: to.params.inviteId as string }, true);
    }

    if ($auth.user && to.name !== 'domains' && !$auth.isUserVerified) {
        return next({ name: 'domains' });
    }

    return next();
}
