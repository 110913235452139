import { z } from 'zod';

import { Http } from '@/helpers/http';

import { NameSchema } from '@/schemas/name.schema';
import { PaymentCardCreateSchema } from '@/schemas/payment-card-create.schema';

import { PaymentCard } from '@/models/payment-card.model';

const url = `${import.meta.env.VITE_API_URL}/payment-cards`;

export const PaymentCardService = Object.freeze({
    async getMyPaymentCards() {
        return await Http.get<PaymentCard[]>(`${import.meta.env.VITE_API_URL}/users/me/payment-cards`);
    },

    async getTeamPaymentCards(teamId: string) {
        return await Http.get<PaymentCard[]>(`${import.meta.env.VITE_API_URL}/teams/${teamId}/payment-cards`);
    },

    async show(id: string) {
        return await Http.get<PaymentCard>(`${url}/${id}`);
    },

    /**
     * Create a new payment card.
     * @returns A Reepay session link
     */
    async store(data: z.infer<typeof PaymentCardCreateSchema>) {
        PaymentCardCreateSchema.parse(data);

        return await Http.post<string>(`${url}`, data);
    },

    async update(id: string, data: z.infer<typeof NameSchema>) {
        NameSchema.parse(data);

        return await Http.patch<string>(`${url}/${id}`, data);
    },

    async destroy(id: string) {
        return await Http.delete(`${url}/${id}`);
    },
});
