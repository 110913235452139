import { UserCreateSchema } from '@/schemas/user-create.schema';

export const UserUpdateSchema = UserCreateSchema.pick({
    name: true,
    street: true,
    zip: true,
    city: true,
    country_code: true,
});

export const UserSamleikinUpdateSchema = UserCreateSchema.pick({
    street: true,
    zip: true,
    city: true,
    country_code: true,
});
