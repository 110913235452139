import router from '@/router';

import { z } from 'zod';

import { Http } from '@/helpers/http';

import { ChangePhoneSchema } from '@/schemas/change-phone.schema';
import { EmailVerifySchema } from '@/schemas/email-verify.schema';
import { EmailSchema } from '@/schemas/email.schema';
import { LoginSchema } from '@/schemas/login.schema';
import { PhoneVerifySchema } from '@/schemas/phone-verify.schema';
import { RequestLoginSchema } from '@/schemas/request-login.scema';
import { ResetPasswordSchema } from '@/schemas/reset-password.schema';
import { UserAbroadCreateSchema } from '@/schemas/user-abroad-create.schema';

import { AccessToken } from '@/models/access-token.model';

const url = `${import.meta.env.VITE_API_URL}/auth`;

export const AuthService = Object.freeze({
    async requestLogin(data: z.infer<typeof RequestLoginSchema>) {
        RequestLoginSchema.parse(data);

        return await Http.post<{ verification_id: string; is_phone_verified: boolean }>(`${url}/request-login`, data);
    },

    async login(data: z.infer<typeof LoginSchema>) {
        LoginSchema.parse(data);

        return await Http.post<{ token: string }>(`${url}/login`, data);
    },

    async forgotPassword(data: z.infer<typeof EmailSchema>) {
        EmailSchema.parse(data);

        return await Http.post(`${url}/forgot-password`, data);
    },

    async resetPassword(data: z.infer<typeof ResetPasswordSchema>) {
        ResetPasswordSchema.parse(data);

        return await Http.post(`${url}/reset-password`, data);
    },

    async storeUserAbroad(data: z.infer<typeof UserAbroadCreateSchema>) {
        UserAbroadCreateSchema.parse(data);

        return await Http.post(`${url}/register-abroad-user`, data);
    },

    async updatePhone(data: z.infer<typeof ChangePhoneSchema>) {
        ChangePhoneSchema.parse(data);

        return await Http.patch(`${url}/update-phone`, data);
    },

    async verifyPhone(data: z.infer<typeof PhoneVerifySchema>) {
        PhoneVerifySchema.parse(data);

        return await Http.post(`${url}/verify-phone`, data);
    },

    async resendPhoneVerification(data: z.infer<typeof ChangePhoneSchema>) {
        ChangePhoneSchema.parse(data);

        return await Http.post(`${url}/resend-phone-verification`, data);
    },

    async updateEmail(data: z.infer<typeof EmailSchema>) {
        EmailSchema.parse(data);

        return await Http.patch(`${url}/update-email`, data);
    },

    async verifyEmail(data: z.infer<typeof EmailVerifySchema>) {
        EmailVerifySchema.parse(data);

        return await Http.post(`${url}/verify-email`, data);
    },

    async resendEmailVerification(data: z.infer<typeof EmailSchema>) {
        EmailSchema.parse(data);

        return await Http.post(`${url}/resend-email-verification`, data);
    },

    async getTokens() {
        return await Http.get<AccessToken[]>(`${url}/tokens`);
    },

    async deleteToken(id: string | number) {
        return await Http.delete(`${url}/tokens/${id}`);
    },

    async logout() {
        $store.conceal = true;

        await Http.post(`${url}/logout`);

        localStorage.removeItem('økisnavn_access_token');

        Session.removeAll();

        $auth.user = undefined;

        router.push({ name: 'auth-login' });
    },
});
