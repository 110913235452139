import { ref, Ref } from 'vue';

import { defineStore } from 'pinia';

import { CountryService } from '@/services/country.service';
import { PaymentCardService } from '@/services/payment-card.service';
import { SettingsService } from '@/services/settings.service';
import { TeamService } from '@/services/team.service';

import { Country } from '@/models/country.model';
import { Domain } from '@/models/domain.model';
import { Invoice } from '@/models/invoice.model';
import { PaymentCard } from '@/models/payment-card.model';
import { Settings } from '@/models/settings.model';
import { Team } from '@/models/team.model';

export const useStore = defineStore('store', () => {
    // Payments
    const paymentCards: Ref<PaymentCard[]> = ref([]);
    const invoices: Ref<Invoice[]> = ref([]);

    // Domains
    const domains: Ref<Domain[]> = ref([]);
    const searchDomainString = ref('');
    const domainToPurchase = ref({
        domains: [''] as [string, ...string[]],
        duration_years: 0,
    });

    // Team
    const teams: Ref<Team[]> = ref([]);
    const isTeamOwner = ref(false);

    // Session storage data
    const teamId = ref<string>();
    const shared = ref(false);

    // General
    const countries: Ref<Country[]> = ref([]);
    const settings = ref<Settings>();
    const notificationsIsOpen = ref(false);
    const isSidebarOpen = ref(false);
    const conceal = ref(true);

    const videoUrl = ref('');

    return {
        notificationsIsOpen,
        domains,
        searchDomainString,
        domainToPurchase,
        paymentCards,
        invoices,
        teamId,
        shared,
        isTeamOwner,
        teams,
        settings,
        isSidebarOpen,
        countries,
        videoUrl,
        conceal,

        // Functions

        async getTeams(refresh?: boolean) {
            if (teams.value.length > 0 && !refresh) {
                return teams.value;
            }

            const { response, error } = await TeamService.index();

            if (error) {
                return [];
            }

            teams.value = response.data;

            return response.data;
        },

        async getPaymentCards() {
            if (teamId.value) {
                const { response, error } = await PaymentCardService.getTeamPaymentCards(teamId.value);

                if (error) {
                    return [];
                }

                paymentCards.value = response.data;

                return response.data;
            }

            const { response, error } = await PaymentCardService.getMyPaymentCards();

            if (error) {
                return [];
            }

            paymentCards.value = response.data;

            return response.data;
        },

        async getCountries() {
            if (countries.value.length < 1) {
                const { response, error } = await CountryService.index();

                if (error) {
                    return [];
                }

                countries.value = response.data;
            }

            return countries.value;
        },

        async setData(data?: { teamId?: string; shared?: boolean }) {
            let storageData: { teamId?: string; shared?: boolean } = { teamId: undefined, shared: false };

            if (data?.teamId) {
                const { response } = await TeamService.show(data.teamId);

                if (response) {
                    isTeamOwner.value = response.data.is_owned_by_current_user;
                }

                storageData = { teamId: data.teamId, shared: false };
            }

            if (data?.shared) {
                storageData = { teamId: undefined, shared: true };
            }

            teamId.value = storageData.teamId;
            shared.value = !!storageData.shared;

            await Session.set('data', storageData);
        },

        async getData() {
            let returnValue = { teamId: undefined, shared: false };

            const data = await Session.get('data');

            if (data) {
                returnValue = data;
            }

            teamId.value = returnValue.teamId;
            shared.value = returnValue.shared;

            if (teamId.value) {
                const { response } = await TeamService.show(teamId.value);

                if (response) {
                    isTeamOwner.value = response.data.is_owned_by_current_user;
                }
            }

            return returnValue;
        },

        async getSettings() {
            if (settings.value) {
                return settings.value;
            }

            const { response, error } = await SettingsService.index();

            if (error) {
                return;
            }

            settings.value = response.data;
        },
    };
});
