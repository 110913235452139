import { onUnmounted } from 'vue';

import { v4 as uuid } from 'uuid';

type EventHandler = {
    name: string;
    callback: (...args: any[]) => void;
};

// Map of component id to event handlers.
const componentEventHandlers = new Map<string, EventHandler[]>();

/**
 * Create an event bus.
 */
export function useEventBus() {
    // Generate a unique id for this component.
    const id = uuid();

    componentEventHandlers.set(id, []);

    // Remove event handlers when component is unmounted.
    onUnmounted(() => {
        componentEventHandlers.delete(id);
    });

    return {
        listen(name: string, callback: (...args: any[]) => void) {
            componentEventHandlers.get(id)!.push({
                name,
                callback,
            });
        },

        emit(name: string, ...args: any[]) {
            const matchingHandlers = [];

            for (const handlers of componentEventHandlers.values()) {
                for (const handler of handlers.filter((h) => h.name === name)) {
                    matchingHandlers.push(handler);
                }
            }

            for (const handler of matchingHandlers) {
                handler.callback(...args);
            }
        },

        async unlisten(name: string) {
            const eventHandlers = componentEventHandlers.get(id)!;

            componentEventHandlers.set(
                id,
                eventHandlers.filter((eventHandler) => eventHandler.name != name),
            );
        },

        clear() {
            componentEventHandlers.set(id, []);
        },
    };
}
