import { watch, type Ref, ref } from 'vue';

import * as focusTrap from 'focus-trap';

export function useFocusTrap(el: Ref) {
    const active = ref(false);

    let trap: null | ReturnType<(typeof focusTrap)['createFocusTrap']> = null;

    watch(el, (el) => {
        if (!el) {
            return;
        }

        trap = focusTrap.createFocusTrap(el, {
            allowOutsideClick: true,
        });
    });

    watch(active, (active) => {
        if (!trap) {
            return;
        }

        try {
            if (active) {
                trap.activate();
            } else {
                trap.deactivate();
            }
        } catch (_e) {
            console.warn('useFocusTrap(): Please make sure that at least one element in the container is focusable.');
        }
    });

    return {
        setActive(value: boolean) {
            active.value = value;
        },
    };
}
