<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import Cleave from 'cleave.js';
import type { CleaveOptions } from 'cleave.js/options';

import { useEventBus } from '@/composables/event-bus';

import { excludeProperties } from '@/helpers';

// Options

defineOptions({
    inheritAttrs: false,
});

// Props

const props = defineProps<{
    modelValue?: string;
    locked?: boolean;
    label?: string;
    errorMessage?: string;
    number?: boolean;
    cleave?: CleaveOptions;
    textarea?: boolean;
    type?: 'text' | 'password' | 'email' | 'number' | 'tel';
    readOnly?: boolean;
    revealable?: boolean;
}>();

// Emits

const emit = defineEmits<{
    'update:modelValue': [string];
    enter: [];
    escape: [];
}>();

// Expose

defineExpose({
    focus: () => {
        if (!inputRef.value) {
            return;
        }

        inputRef.value.focus();
    },

    state: computed(() => ({
        dirty: isDirty.value,
        inFocus: isInFocus.value,
    })),

    setDirty: (value: boolean) => {
        isDirty.value = value;
    },

    reveal: () => {
        isRevealed.value = true;
    },
});

// Composables

const eventBus = useEventBus();

// Data

const inputRef = ref<HTMLInputElement>();
const localCleave = ref<Cleave>();
const isDirty = ref(false);
const isInFocus = ref(false);
const isRevealable = ref(props.revealable);
const isRevealed = ref(false);

// Computed

const showErrors = computed(() => !!props.errorMessage?.length && !isInFocus.value && isDirty.value);

const getRevealableValue = computed(() => {
    if (isRevealable.value) {
        if (isRevealed.value) {
            return props.modelValue;
        }

        return getDots(props.modelValue || '');
    }

    return props.modelValue;
});

// Events

eventBus.listen('input:clean-all', () => {
    isDirty.value = false;
});

eventBus.listen('input:touch-all', () => {
    isDirty.value = true;
});

// Lifecycle Hooks

onMounted(async () => {
    initCleave();
});

// Functions

function initCleave() {
    if (!inputRef.value || !props.cleave) {
        return;
    }

    localCleave.value = new Cleave(inputRef.value, {
        ...props.cleave,
        async onValueChanged(e) {
            emit('update:modelValue', e.target.value); // This is where modelValue gets updated, if Cleave.js is enabled.
        },
    });
}

/**
 * Set the input's value.
 */
async function setValue(e: Event) {
    isInFocus.value = true;
    isDirty.value = true;

    if (localCleave.value) {
        e.preventDefault();

        return;
    }

    const target = e.target as HTMLInputElement;

    emit('update:modelValue', target.value); // This is where modelValue gets updated, if Cleave.js is not enabled.
}

function handleKeyDown(event: KeyboardEvent) {
    if (props.readOnly) {
        event.preventDefault();

        return;
    }

    if (event.key === 'Escape') {
        emit('escape');
    }

    if (event.key === 'Enter') {
        emit('enter');
    }

    if (props.number && event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
    }
}

function getDots(text: string) {
    return '•'.repeat(text.length);
}
</script>

<template>
    <div
        class="flex w-full flex-col gap-1"
        :class="$attrs.class"
    >
        <label
            v-if="label"
            :for="`v-input-${$.uid}`"
            class="ml-3 text-sm text-black-200"
            :class="{
                'text-danger': showErrors,
                'pointer-events-none': locked,
            }"
        >
            {{ label }}
        </label>

        <div
            class="relative overflow-hidden rounded-lg"
            :class="{ 'shadow-small': isInFocus }"
        >
            <component
                :is="textarea ? 'textarea' : 'input'"
                :id="`v-input-${$.uid}`"
                ref="inputRef"
                :readonly="locked"
                :tabindex="locked ? -1 : 0"
                :value="getRevealableValue"
                :placeholder="($attrs.placeholder as string) || ''"
                :type="type"
                class="w-full resize-none border-0 px-3 py-[1.125rem] placeholder:text-black-100 focus:outline-none focus:ring-0"
                :class="{
                    'pointer-events-none': locked,
                }"
                rows="4"
                v-bind="excludeProperties($attrs, ['class'])"
                @input="setValue"
                @keydown="handleKeyDown"
                @focus="isInFocus = true"
                @blur="isInFocus = false"
            />

            <VIcon
                v-if="locked"
                class="absolute bottom-1/2 right-3 h-5 w-5 translate-y-1/2"
                name="padlock"
            />

            <div
                v-if="isRevealable"
                class="absolute bottom-1/2 right-3 grid h-8 w-8 translate-y-1/2 cursor-pointer place-items-center"
                @click="isRevealed = !isRevealed"
            >
                <VIcon
                    class="w-5"
                    :name="isRevealed ? 'eye-close' : 'eye'"
                />
            </div>

            <div
                class="absolute bottom-0 left-0 h-[2px] w-full bg-black opacity-0 transition duration-400"
                :class="{
                    'bg-danger opacity-100': showErrors,
                }"
            />
        </div>

        <div
            v-if="showErrors"
            class="ml-3 flex flex-col"
        >
            <div class="text-sm text-danger">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>
