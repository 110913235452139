import axios from 'axios';

import i18n from '@/i18n';

import { useAuthStore } from '@/store/auth.store';

export async function initAxios() {
    setAcceptHeader();
    addAuthInterceptor();
    addLanguageHeaderInterceptor();
}

function setAcceptHeader() {
    axios.interceptors.request.use((config) => {
        if (!config.headers.Accept) {
            config.headers.Accept = 'application/json';
        }

        return config;
    });
}

function addAuthInterceptor() {
    axios.interceptors.request.use(async (config) => {
        if (!config.headers) {
            return config;
        }

        if (config.headers.Authorization) {
            return config;
        }

        const authStore = useAuthStore();

        const accessToken = await authStore.getAccessToken();

        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
    });
}

function addLanguageHeaderInterceptor() {
    axios.interceptors.request.use((config) => {
        if (!config.headers) {
            return config;
        }

        config.headers['Accept-Language'] = i18n.global.locale.value;

        return config;
    });
}
