<script setup lang="ts">
import { ref } from 'vue';

// Props

const props = defineProps<{
    text?: string;
    type?: 'default' | 'secondary' | 'danger';
    submit?: boolean;
    small?: boolean;
    loading?: boolean;
}>();

// Emits

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();

// Expose

defineExpose({
    click: () => {
        if (!element.value) {
            return;
        }

        element.value.click();
    },
});

// Data

const element = ref();

// Functions

function click(e: MouseEvent) {
    e.preventDefault();

    if (props.loading) {
        return;
    }

    emit('click', e);
}
</script>

<template>
    <button
        ref="element"
        :type="submit ? 'submit' : 'button'"
        class="btn inline-flex items-center gap-2.5"
        :class="[{ small: small }, type]"
        @click="click"
    >
        <div v-if="loading">
            <VIcon
                name="loader"
                class="h-4 w-4 animate-spin"
            />
        </div>

        <span
            v-if="text"
            class="w-full"
            v-html="text"
        />

        <slot />
    </button>
</template>
