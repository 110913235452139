<template>
    <div class="fixed bottom-4 right-4 z-50 mb-4 w-full max-w-sm px-4">
        <div class="relative">
            <TransitionGroup name="list">
                <div
                    v-for="toast in $toast.registeredToasts.value"
                    :key="toast.id"
                    class="relative mb-1 flex w-full items-start gap-4 rounded-2xl bg-white p-5 pb-4 shadow-large"
                >
                    <VIcon
                        :name="toast.type"
                        class="mt-1 w-4 flex-shrink-0 text-black"
                        :class="{
                            '!text-danger': toast.type == 'error',
                        }"
                    />

                    <p
                        class="flex-1 break-words pr-5"
                        v-html="toast.message"
                    />

                    <div
                        class="-mt-[0.45rem] flex-shrink-0 cursor-pointer p-1"
                        @click.prevent="$toast.remove(toast.id)"
                    >
                        <VIcon
                            name="close-circle"
                            class="w-7"
                        />
                    </div>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<style>
.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateY(8px);
}

.list-leave-active {
    position: absolute;
}
</style>
