<script setup lang="ts">
import { computed } from 'vue';

import i18n from '@/i18n';

import TheLogo from './TheLogo.vue';

import VSidebarItem from '@/components/VSidebarItem.vue';
import TheSidebarSelect from '@/components/layout/TheSidebarSelect.vue';

// Computed

const showSelect = computed(() => {
    if (!$auth.isUserVerified) {
        return false;
    }

    return $store.teams.length > 0 || $auth.user?.has_shared_domains;
});

const renderAllSidebarItems = computed(() => {
    if ($store.shared || ($store.teamId && !$store.isTeamOwner)) {
        return false;
    }

    return true;
});

const privacyLink = computed(() => {
    if ($store.settings?.url_for_privacy_policy) {
        const url_for_privacy_policy = JSON.parse($store.settings.url_for_privacy_policy);

        const locale = i18n.global.locale.value;

        return url_for_privacy_policy[locale];
    }

    return '#';
});

// Functions

function goToHelp() {
    if ($store.settings?.url_for_help_page) {
        const url_for_help_page = JSON.parse($store.settings.url_for_help_page);

        const locale = i18n.global.locale.value;

        window.open(url_for_help_page[locale], '_blank');
    }
}
</script>

<template>
    <aside class="lg:w-60 lg:min-w-[15rem]">
        <div
            class="fixed bottom-0 left-0 top-0 z-50 flex w-full flex-col justify-between bg-background transition duration-400 lg:z-0 lg:w-60 lg:!translate-x-0"
            :class="{ '-translate-x-full': !$store.isSidebarOpen }"
        >
            <div>
                <div class="flex items-center justify-between pb-6 pl-2 pr-4 pt-3.5 lg:justify-center lg:py-11">
                    <VIcon
                        name="close"
                        class="w-10 lg:hidden"
                        @click="$store.isSidebarOpen = false"
                    />
                    <TheLogo
                        class="w-32 cursor-pointer lg:w-40"
                        @click="$routing.go($router, 'domains')"
                    />
                </div>

                <div v-if="$slots['sidebar-items']">
                    <slot name="sidebar-items" />
                </div>

                <div
                    v-else
                    class="flex flex-col px-3"
                >
                    <div
                        v-if="showSelect"
                        class="rounded-t-lg bg-black-50 px-3 py-3.5"
                    >
                        <TheSidebarSelect />
                    </div>
                    <div
                        class="mb-3 flex flex-col gap-1 border-b border-black-50 px-2 pb-3"
                        :class="{ 'rounded-b-lg border-x pt-3': showSelect }"
                    >
                        <VSidebarItem
                            icon="sidebar-domain"
                            :label="$t('Økisnøvn')"
                            route-name="domains|domains-shared|domains-single|search-domain|teams-search-domain|purchase-domain|teams-domains"
                        />

                        <template v-if="renderAllSidebarItems">
                            <VSidebarItem
                                icon="sidebar-payment-method"
                                :label="$t('Gjaldshættir')"
                                route-name="payment-methods|teams-payment-methods"
                            />

                            <VSidebarItem
                                v-if="$store.teamId"
                                icon="sidebar-team"
                                :label="$t('Umsit toymið')"
                                route-name="teams-single"
                            />

                            <VSidebarItem
                                icon="sidebar-move-domain"
                                :label="$t('Flyt økisnavn')"
                                route-name="transfer-domain|teams-transfer-domain"
                            />
                        </template>
                    </div>

                    <div class="flex flex-col gap-1 px-2">
                        <VSidebarItem
                            icon="sidebar-help"
                            :label="$t('Vegleiðingar')"
                            small
                            @click="goToHelp"
                        />

                        <VSidebarItem
                            icon="sidebar-user"
                            :label="$t('Mín brúkari')"
                            route-name="my-user"
                            small
                        />
                        <VSidebarItem
                            v-if="$auth.user?.is_admin"
                            icon="sidebar-enter-admin"
                            :label="$t('Umsiting')"
                            route-name="admin"
                            small
                        />
                    </div>
                </div>
            </div>

            <div v-if="$auth.user">
                <div
                    class="flex w-full cursor-pointer items-center justify-between px-6 py-5 lg:w-60"
                    @click="$auth.logout()"
                >
                    <p>{{ $t('Rita út') }}</p>

                    <VIcon
                        name="logout"
                        class="w-6"
                    />
                </div>

                <div class="flex items-center justify-center pb-5">
                    <a
                        :href="privacyLink"
                        target="_blank"
                        class="text-xs text-black-100 underline"
                    >
                        {{ $t('Privatlívspolitikkur') }}
                    </a>
                </div>
            </div>
        </div>
    </aside>
</template>
