import { Router } from 'vue-router';

export const Routing = {
    async go(router: Router, routeName: string, params?: Record<string, string | number>, query?: Record<string, string | number>): Promise<void> {
        const whitelist = [
            'admin',
            'admin-users',
            'admin-domains',
            'admin-invoices',
            'admin-statistics',
            'admin-settings',
            'admin-activity-log',
            'my-user',
            'teams-single',
            'team-invite-accept',
            'domain-invite-accept',
            'auth-login',
            'auth-reset-password',
            'auth-create-abroad-user',
            '404',
        ];

        if (whitelist.includes(routeName)) {
            router.push({ name: routeName, params, query });
            return;
        }

        const storage = await $store.getData();

        if (storage.teamId) {
            const pathName = `teams-${routeName}`;

            router.push({ name: pathName, params: { ...params, teamId: storage.teamId }, query });

            return;
        }

        router.push({ name: routeName, params, query });
    },
};
