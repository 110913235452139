<script setup lang="ts">
import { useRouter } from 'vue-router';

// Composables

const router = useRouter();

// Props

const props = defineProps<{
    icon: string;
    label: string;
    routeName?: string;
    small?: boolean;
}>();

// Functions

function handleClick() {
    if (!props.routeName) {
        return;
    }

    $store.isSidebarOpen = false;

    const firstRoute = props.routeName.split('|')[0];

    if (firstRoute === 'teams-single') {
        Routing.go(router, firstRoute, { teamId: $store.teamId as string });
        return;
    }

    if (firstRoute === 'domains' && $store.shared) {
        Routing.go(router, 'domains-shared');
        return;
    }

    Routing.go(router, firstRoute);
}
</script>

<template>
    <div
        class="flex cursor-pointer items-center gap-3 rounded-lg px-1.5 py-2"
        :class="{ 'bg-black-50': routeName?.includes($route.name as string) }"
        @click="handleClick"
    >
        <div class="flex h-6 w-6 items-center justify-center">
            <VIcon :name="icon" />
        </div>
        <p
            class="text-sm"
            :class="{ '!text-xs': small }"
        >
            {{ label }}
        </p>
    </div>
</template>
