import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export async function guestGuard(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    await $auth.waitUntilFetched();

    if (!to.meta.guest) {
        return next();
    }

    if ($auth.user) {
        return next({ name: 'domains' });
    }

    return next();
}
