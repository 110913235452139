import { DirectiveBinding } from 'vue';

import tippy, { Props } from 'tippy.js';

export const tippyDirective = {
    mounted(el: any, binding: DirectiveBinding<string> | DirectiveBinding<Partial<Props>>) {
        if (typeof binding.value == 'string') {
            tippy(el, {
                content: binding.value,
            });

            return;
        }

        tippy(el, binding.value);
    },

    unmounted(el: any) {
        el._tippy.destroy();
    },
};
