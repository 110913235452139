import axios, { AxiosError, AxiosResponse, type AxiosRequestConfig } from 'axios';

export type ErrorResponse = {
    message: string;
};

export type HttpResponse<T> = { response: AxiosResponse<T>; error: null } | { response: null; error: AxiosError<ErrorResponse> };

export async function createHttpResponse<T>(promise: Promise<AxiosResponse<T>>): Promise<HttpResponse<T>> {
    try {
        const response = await promise;

        return { response, error: null };
    } catch (error) {
        const axiosError = error as AxiosError<ErrorResponse>;

        return { response: null, error: axiosError };
    }
}

export const Http = {
    async get<T>(url: string, config: AxiosRequestConfig = {}) {
        return await createHttpResponse<T>(axios.get(url, config));
    },

    async post<T>(url: string, data: any = {}, config: AxiosRequestConfig = {}) {
        return await createHttpResponse<T>(axios.post(url, data, config));
    },

    async patch<T>(url: string, data: any = {}, config: AxiosRequestConfig = {}) {
        return await createHttpResponse<T>(axios.patch(url, data, config));
    },

    async delete<T>(url: string, config: AxiosRequestConfig = {}) {
        return await createHttpResponse<T>(axios.delete(url, config));
    },
};
